// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-still-js": () => import("/opt/build/repo/src/templates/still.js" /* webpackChunkName: "component---src-templates-still-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-still-js": () => import("/opt/build/repo/src/pages/still.js" /* webpackChunkName: "component---src-pages-still-js" */),
  "component---src-templates-video-js": () => import("/opt/build/repo/src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

